import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSelector } from "../../../../redux/store/GlobalState";
import { postData } from "../../../../redux/utils/fetchData";

const Settings = () => {
  const set = useSelector((s) => s.settings);
  const token = useSelector((s) => s.auth.token);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error] = useState(null);
  const { register, handleSubmit, formState, clearErrors, setValue } =
    useForm();
  React.useEffect(() => {
    if (set.commission) {
      register("commission");
      setValue("commission", set.commission);
    }
  }, [register, set, setValue]);
  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, formState?.errors, clearErrors, register]);

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    delete data?.confirm_password;
    const newData = {
      commission: data.commission,
    };
    postData(`settings`, newData, token)
      .then((res) => {
        setIsLoading(false);
        if (res._id) {
          toast.success("Paramètres modifié avec succés.");
        } else {
          toast.error("Paramètres non modifié.");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Oops! Une erreur est survenue lors de la modification.");
      });
  };
  return (
    <div className="easypm-tabs-item-content-container">
      <div className="easypm-tabs-item">
        <div className="add-family-form-container">
          <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row row-form">
              <div className="col-md-4 crud-col-item">
                <div className="form-group crud-form-group required">
                  <label htmlFor="commission">Commission</label>
                  <input
                    {...register("commission", {
                      required: "Le champ commission est requis.",
                    })}
                    type="number"
                    placeholder="Commission (exemple 10 pour 10%)"
                    name="commission"
                    className="form-control crud-form-control"
                    id="commission"
                  />
                  {formState?.errors && formState?.errors?.commission && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.commission?.message}
                    </div>
                  )}
                  {error && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {error}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-3 offset-md-9 crud-form-action-btn-container">
                {isLoading && (
                  <button className="btn btn-primary crud-submit-btn" disabled>
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
                {!isLoading && (
                  <button className="btn btn-primary crud-submit-btn">
                    Soumettre
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
