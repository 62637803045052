import React, { useContext, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import ResetPasswordModal from "./ResetPasswordModal";
import EmptyHeader from "./EmptyHeader";
import axios from "axios";
import api from "../../Http/global-vars";
import { DataContext } from "../../redux/store/GlobalState";
// import { ContactsOutlined } from '@material-ui/icons';

const Connexion = () => {
  const { dispatch } = useContext(DataContext);
  // let navigate = useNavigate()
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const [is_empty_email, setIs_empty_email] = useState(false);
  const [is_empty_password, setIs_empty_password] = useState(false);

  const handleChange = (e) => {
    // let fields = fields;
    fields[e.target.name] = e.target.value;

    if (e.target.name === "email") {
      fields[e.target.name] = e.target.value.toLowerCase();
    }

    setFields(fields);
    console.log(fields);
  };

  const onValidateLoginForm = () => {
    // let fields = fields;
    // let errors = errors;
    var isValidForm = true;

    if (!fields["email"]) {
      isValidForm = false;
      setIs_empty_email(true);
      setTimeout(() => {
        setIs_empty_email(false);
      }, 5000);

      errors["email"] = "Ce champ est obligatoire";
    }
    if (!fields["password"]) {
      isValidForm = false;

      setIs_empty_password(true);
      setTimeout(() => {
        setIs_empty_password(false);
      }, 5000);

      errors["password"] = "Ce champ est obligatoire";
    }

    setErrors(errors);

    return isValidForm;
  };

  const onSubmitLogin = (e) => {
    e.preventDefault();
    if (onValidateLoginForm()) {
      setIsLoginInProgress(true);

      // var api = api;
      var data = fields;
      var fd = new FormData();
      fd.append("email", data.email);
      fd.append("password", data.password);

      // return console.log(data.email.toLowerCase())

      axios
        .post(api + "auth/login-admin", fields)
        .then((response) => {
          const { user, token } = response.data;
          window.sessionStorage.setItem(
            "auth",
            JSON.stringify({ user, token })
          );
          dispatch({
            type: "AUTH",
            payload: { user, token },
          });
          setTimeout(() => {
            window.location = "/dashboard";
          }, 600);
        })
        .catch((ex) => {
          const { error } = ex.response.data;
          if (!error) {
            toast.error("Email ou mot de passe incorrect .");
          } else {
            toast.error(error);
          }
          setIsLoginInProgress(false);
        });
    }
  };

  return (
    <>
      <EmptyHeader />
      <div className="">
        <div className="row login-page-row">
          <div className="col-md-6 offset-md-3 right-side-col">
            <div className="login-form-container">
              <div className="login-form-introduction d-flex justify-content-center">
                <h1 className="login-title-label">Connectez-vous</h1>
              </div>
              <form id="login-form">
                <div className="input-group my-4">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="user" />
                    </span>
                  </div>
                  <input
                    name="email"
                    id="email"
                    onChange={(e) => handleChange(e)}
                    type="text"
                    className="form-control input-with-icon"
                    placeholder="Identifiant"
                  />
                  {is_empty_email && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {errors.email}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-4">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="lock" />
                    </span>
                  </div>
                  <input
                    name="password"
                    id="password"
                    onChange={(e) => handleChange(e)}
                    type="password"
                    className="form-control input-with-icon"
                    placeholder="Mot de passe"
                  />
                  {is_empty_password && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {errors.password}{" "}
                    </div>
                  )}
                </div>

                <div className="dis-flex justify-content-end m-b-40">
                  <button
                    onClick={(e) => e.preventDefault()}
                    type="button"
                    className="mdpOublie"
                    data-toggle="modal"
                    data-target=".open-reset-password-modal"
                  >
                    Mot de passe oublié ?
                  </button>
                </div>

                <ResetPasswordModal />

                <div className="row submit-btn-row">
                  <div className="col-md-8 offset-md-2 submit-btn-col">
                    {isLoginInProgress === false ? (
                      <button
                        type="submit"
                        onClick={(e) => onSubmitLogin(e)}
                        className="btn-connexion"
                      >
                        Connexion
                      </button>
                    ) : (
                      <button className="btn-connexion in-progress-btn">
                        Connexion en cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Connexion;
