import React from "react";
import FeatherIcon from "feather-icons-react";
import * as IconlyPack from "react-iconly";
import userAvatar from "../../images/icons/userAvatar.png";
import NavBarMobileV2 from "../Backend/navbarMobileV2/navbarMobileV2";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/favicon.png";
import { useDispatch, useSelector } from "../../redux/store/GlobalState";

var Header = () => {
  const user = useSelector((s) => s.auth?.user || {});
  const dispatch = useDispatch();
  var onLogoutUser = () => {
    window.sessionStorage.removeItem("auth");
    setTimeout(() => {
      dispatch({ type: "LOGOUT" });
    }, 1000);
  };

  return (
    <>
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering">
          <div className="logo-item">
            <div className="logo-container dashboard-header-logo">
              <img
                src={Logo}
                className="logo-img"
                style={{ width: 60, height: "auto" }}
                alt="Fybego"
              />
            </div>
          </div>

          <div className="connected-user-content ml-auto no-view-mobile">
            <div className="dropdown dis-flex">
              <button
                className="user-acount-dashboard-header"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="user-acount-dashboard-header-display">
                  <img
                    src={""}
                    width="45"
                    className="user-avatar"
                    alt="User Avatar"
                  />
                  <FeatherIcon
                    className="icon-user-acount-dashboard"
                    icon="chevron-down"
                  />
                </div>
              </button>
              <div
                className="dropdown-menu dropdown-menu-custom"
                aria-labelledby="dropdownMenuButton"
              >
                <NavLink
                  to="/settings"
                  className="dropdown-item item-dropdown-custom"
                >
                  {user.firstName + " " + user.lastName}
                </NavLink>
                <div className="dropdown-divider"></div>
                <span
                  type="button"
                  className="dropdown-item item-dropdown-custom"
                  onClick={() => onLogoutUser()}
                >
                  {" "}
                  Deconnexion
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="hiddenable-nav-mobile-container no-view-desktop">
          <div className="hiddenable-nav-mobile-content">
            <NavBarMobileV2 />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
