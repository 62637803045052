import React, { Component } from "react";
// import { NavLink } from 'react-router-dom'
import FeatherIcon from "feather-icons-react";
import { ToastContainer } from "react-toastify";
// import isAuthenticate from './../modules/Secure/Secure'
// import ResetPasswordModal from './ResetPasswordModal'
import EmptyHeader from "./EmptyHeader";

export default class ModifierPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      isLoginInProgress: false,
      isSuccessLogin: false,
      isErrorLogin: false,
      is_empty_email: false,
      is_empty_password: false,
      isInvalidCredential: false,
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      userType: window.localStorage.getItem("userType"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmitLogin = this.onSubmitLogin.bind(this);
  }

  componentDidMount() {
    // this.onTestUserConnectedStatus()
  }

  onTestUserConnectedStatus = () => {
    // var isNotConnected = isAuthenticate()
    // var userType = this.state.userType
    // var isFirstConnexion = window.localStorage.getItem('userFirstConnexion')
    // if (!isNotConnected && userType === 'student') {
    //     if (isFirstConnexion === true) {
    //         window.location = '/welcome'
    //     } else {
    //         window.location = '/tous-les-modules'
    //     }
    // } else if (!isNotConnected && userType === 'admin') {
    //     window.location = '/tableau-de-bord'
    // }
  };

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    if (e.target.name === "email") {
      fields[e.target.name] = e.target.value.toLowerCase();
    }

    this.setState({
      fields: fields,
    });
    console.log(fields);
  }

  onValidateLoginForm() {
    // let fields = this.state.fields
    // let errors = this.state.errors
    // var isValidForm = true
    // if (!fields['email']) {
    //     isValidForm = false
    //     this.setState({
    //         is_empty_email: true
    //     })
    //     setTimeout(() => {
    //         this.setState({
    //             is_empty_email: false
    //         })
    //     }, 5000);
    //     errors['email'] = 'Ce champ est obligatoire'
    // }
    // if (!fields['password']) {
    //     isValidForm = false
    //     this.setState({
    //         is_empty_password: true
    //     })
    //     setTimeout(() => {
    //         this.setState({
    //             is_empty_password: false
    //         })
    //     }, 5000);
    //     errors['password'] = 'Ce champ est obligatoire'
    // }
    // this.setState({
    //     errors: errors
    // })
    // return isValidForm;
  }

  onSubmitLogin(e) {
    e.preventDefault();
    this.setState({
      isLoginInProgress: true,
    });

    setTimeout(() => {
      this.setState({
        isLoginInProgress: false,
      });
      window.location = "ipm/dashboard";
    }, 5000);

    // if (this.onValidateLoginForm()) {
    //     this.setState({
    //         isLoginInProgress: true
    //     })

    //     var api = this.state.api
    //     var data = this.state.fields
    //     var fd = new FormData()
    //     fd.append('email', data.email.toLowerCase())
    //     fd.append('password', data.password)

    //     // return console.log(data.email.toLowerCase())

    //     axios.post(api + 'auth/get-token', fd)
    //         .then(response => {
    //             //console.log(response)
    //             if (response.status === 200) {
    //                 if (response.data.status === 'failure') {
    //                     toast.error(response.data.message)

    //                     this.setState({
    //                         isInvalidCredential: true,
    //                         isLoginInProgress: true
    //                     })

    //                     setTimeout(() => {
    //                         this.setState({
    //                             isInvalidCredential: false,
    //                             isLoginInProgress: false
    //                         })
    //                     }, 5000);
    //                 } else {
    //                     var token = response.data.token
    //                     window.localStorage.setItem('userToken', token)
    //                     let requestConfig = {
    //                         headers: { Authorization: `Bearer ${token}` }
    //                     }
    //                     this.onGetConnectedUserInfos(requestConfig)
    //                 }
    //             } else if (response.data.success === false && response.data.invalid_credential === true) {
    //                 toast.error("Vos identifiants de connexion ne sont pas valides !")
    //                 this.setState({
    //                     isInvalidCredential: true
    //                 })

    //                 setTimeout(() => {
    //                     this.setState({
    //                         isInvalidCredential: false
    //                     })
    //                 }, 5000);
    //             }
    //         })
    //         .catch(error => {
    //             console.log(error)
    //             toast.error("Oupsss! Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.")
    //             this.setState({
    //                 isErrorLogin: true,
    //                 isLoginInProgress: false
    //             })

    //             setTimeout(() => {
    //                 this.setState({
    //                     isErrorLogin: false
    //                 })
    //             }, 5000);
    //         })
    // }
  }

  onGetConnectedUserInfos = (config) => {
    // var api = this.state.api
    // axios.get(api + 'me/', config)
    //     .then(response => {
    //         //console.log(response)
    //         this.setState({
    //             isLoginInProgress: false
    //         })
    //         if (response.status === 200) {
    //             window.localStorage.setItem('userID', response.data.data._id)
    //             window.localStorage.setItem('userFirstName', response.data.data.first_name)
    //             window.localStorage.setItem('userLastName', response.data.data.last_name)
    //             window.localStorage.setItem('userFullname', response.data.data.first_name + ' ' + response.data.last_name)
    //             window.localStorage.setItem('userType', response.data.data.user_type)
    //             window.localStorage.setItem('userFirstConnexion', response.data.data.first_connexion)
    //             var userType = response.data.data.user_type
    //             var isFirstConnexion = response.data.data.first_connexion
    //             //console.log(response.data.data)
    //             toast.success("Connexion réussie !")
    //             this.setState({
    //                 isSuccessLogin: true
    //             })
    //             setTimeout(() => {
    //                 this.setState({
    //                     isSuccessLogin: false
    //                 })
    //             }, 5000);
    //             console.log(userType)
    //             if (userType === 'student') {
    //                 if (isFirstConnexion === true) {
    //                     setTimeout(() => {
    //                         window.location = '/welcome'
    //                     }, 3000);
    //                 } else {
    //                     setTimeout(() => {
    //                         window.location = '/tous-les-modules'
    //                     }, 3000);
    //                 }
    //             } else {
    //                 setTimeout(() => {
    //                     window.location = '/tableau-de-bord'
    //                 }, 3000);
    //             }
    //         }
    //     })
    //     .catch(error => {
    //         console.log(error)
    //         toast.error("Oupsss! Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard.")
    //         this.setState({
    //             isErrorLogin: true,
    //             isLoginInProgress: false
    //         })
    //         setTimeout(() => {
    //             this.setState({
    //                 isErrorLogin: false
    //             })
    //         }, 5000);
    //     })
  };

  render() {
    return (
      <>
        <EmptyHeader />
        <div className="">
          <div className="row login-page-row">
            <div className="col-md-6 offset-md-3 right-side-col">
              <div className="logi-reset-form">
                <div className="login-form-introduction d-flex justify-content-center">
                  <h1 className="login-title-label">
                    Récupération de mot de passe{" "}
                  </h1>
                </div>
                <form id="login-form-reset">
                  <div className=" my-4">
                    <p className="text-comment pb-3">
                      Veuillez entrer votre email Nous vous enverrons un lien
                      pour modifier le mot de passe
                    </p>
                  </div>
                  <div className="input-group my-5 py-5">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text span-input-icon"
                        id="basic-addon1"
                      >
                        <FeatherIcon className="icon-input" icon="mail" />
                      </span>
                    </div>
                    <input
                      name="password"
                      id="password"
                      onChange={(e) => this.handleChange(e)}
                      type="email"
                      className="form-control input-with-icon"
                      placeholder="Adresse mail"
                    />
                    {this.state.is_empty_email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {this.state.errors.email}{" "}
                      </div>
                    )}
                  </div>

                  <div className="row submit-btn-row py-5 my-5">
                    <div className="col-md-8 offset-md-2 submit-btn-col">
                      {this.state.isLoginInProgress === false ? (
                        <button
                          type="submit"
                          onClick={(e) => this.onSubmitLogin(e)}
                          className="btn-connexion"
                        >
                          SOUMETTRE
                        </button>
                      ) : (
                        <button className="btn-connexion in-progress-btn">
                          Connexion en cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {this.state.isSuccessLogin ||
        this.state.isErrorLogin ||
        this.state.isInvalidCredential ? (
          <ToastContainer />
        ) : (
          ""
        )}
      </>
    );
  }
}
