import moment from "moment";
import React, { useContext } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { formatAmout } from "../../../Http/global-vars";
import { DataContext } from "../../../redux/store/GlobalState";

var ClientsTable = () => {
  const { state } = useContext(DataContext);
  const { orders } = state;

  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
    exportCSVText: "Exporter en csv",
  };

  const createdAtFormatter = (cell) => {
    return (
      <span className="created_at"> {moment(cell).format("DD/MM/YYYY")} </span>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            <div className="table-container">
              <BootstrapTable
                data={orders}
                striped={true}
                hover={true}
                condensed={true}
                multiColumnSort={2}
                options={options}
                pagination
                search={true}
                version="4"
                bordered={false}
                // exportCSV
              >
                <TableHeaderColumn isKey hidden dataField="_id">
                  Id
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="140"
                  filter={{ type: "TextFilter" }}
                  dataField="number"
                >
                  N° Commande
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="140"
                  filter={{ type: "TextFilter" }}
                  dataFormat={(c) => c.firstName + " " + c.lastName}
                  filterValue={(c) => c.firstName + " " + c.lastName}
                  dataField="owner"
                >
                  Client
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="140"
                  filter={{ type: "TextFilter" }}
                  dataField="ride"
                  dataFormat={(c) => c.owner.firstName + " " + c.owner.lastName}
                  filterValue={(c) =>
                    c.owner.firstName + " " + c.owner.lastName
                  }
                >
                  Voyageur
                </TableHeaderColumn>
                {/* <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="250"
                  filter={{ type: "TextFilter" }}
                  dataField="owner"
                  dataFormat={(c) => c.email}
                  filterValue={(c) => c.email}
                >
                  Adresse email
                </TableHeaderColumn> */}
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="140"
                  filter={{ type: "TextFilter" }}
                  dataField="destinataire"
                  dataFormat={(c) => c.firstName + " " + c.lastName}
                  filterValue={(c) => c.firstName + " " + c.lastName}
                >
                  Déstinataire
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="250"
                  filter={{ type: "TextFilter" }}
                  dataFormat={(c) => c.fromCity + "/" + c.toCity}
                  filterValue={(c) => c.fromCity + "/" + c.toCity}
                  dataField="ride"
                >
                  Départ/Arrivé
                </TableHeaderColumn>

                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="150"
                  dataAlign="center"
                  dataField="createdAt"
                  dataFormat={(cell, row) => createdAtFormatter(cell, row)}
                >
                  Date
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="100"
                  dataField="kilos"
                >
                  KG
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    textAlign: "center",
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  dataField="amount"
                  dataFormat={(c) => formatAmout(c)}
                  width="70"
                >
                  Prix/KG
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    textAlign: "center",
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  dataField="status"
                  dataFormat={(c) => getStatut(c)}
                  width="70"
                >
                  Statut
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsTable;

function getStatut(status) {
  const Comp = ({ backgroundColor, text }) => (
    <div
      style={{
        backgroundColor: backgroundColor,
        fontWeight: "bold",
        padding: 5,
      }}
    >
      {text}
    </div>
  );
  if (status === "waiting")
    return <Comp backgroundColor={"gray"} text={"En attente"} />;
  if (status === "inprogress")
    return <Comp backgroundColor={"yellow"} text={"En cours"} />;
  if (status === "done")
    return <Comp backgroundColor={"green"} text={"Terminée"} />;
  return <Comp backgroundColor={"red"} text={"Rejetée"} />;
}
