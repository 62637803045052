import React, { useContext } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import { DataContext, useSelector } from "../../../redux/store/GlobalState";

import api, { formatAmout } from "../../../Http/global-vars";
import Axios from "axios";
import { getFileUrl } from "../../../Http/backend-base-url";
import VirementModal from "../../Modals/JoindreDocModal";
import { getData } from "../../../redux/utils/fetchData";

var ClientsTable = () => {
  const { state, dispatch } = useContext(DataContext);
  const { users, auth } = state;

  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
    exportCSVText: "Exporter en csv",
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        <IsActiveButton item={row} />
        {row.funds > 0 && (
          <button
            data-toggle="modal"
            data-target={"#virement" + row?._id}
            className="btn btn-primary crud-submit-btn ml-2"
          >
            Virement
          </button>
        )}
        <div
          className={"modal fade easypm-modal edit-prestation-modal"}
          id={"virement" + row?._id}
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="virement"
          aria-hidden="true"
        >
          <VirementModal
            user={row}
            onDone={() => {
              getData("users", auth.token)
                .then((res) => {
                  dispatch({
                    type: "USERS",
                    payload: res,
                  });
                })
                .catch((ex) => null);
              getData("transactions", auth.token)
                .then((res) => {
                  dispatch({
                    type: "TRANSACTIONS",
                    payload: res,
                  });
                })
                .catch((ex) => null);
            }}
          />
        </div>
      </div>
    );
  };

  const CardIdFormatter = (cell, row) => {
    if (!row.cardId) {
      return <span>Non renseigner</span>;
    }
    return (
      <a title="Voir le fichier" target="_blank" href={getFileUrl(row.cardId)}>
        Voir
      </a>
    );
  };

  const createdAtFormatter = (cell) => {
    return (
      <span className="created_at"> {moment(cell).format("DD/MM/YYYY")} </span>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            <div className="table-container">
              <BootstrapTable
                data={users}
                striped={true}
                hover={true}
                condensed={true}
                multiColumnSort={2}
                options={options}
                pagination
                search={true}
                version="4"
                bordered={false}
                // exportCSV
              >
                <TableHeaderColumn isKey hidden dataField="_id">
                  Prénom
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="140"
                  filter={{ type: "TextFilter" }}
                  dataField="firstName"
                >
                  Prénom
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="140"
                  filter={{ type: "TextFilter" }}
                  dataField="lastName"
                >
                  Nom
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="250"
                  filter={{ type: "TextFilter" }}
                  dataField="email"
                >
                  Adresse email
                </TableHeaderColumn>
                {/* <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="200"
                  filter={{ type: "TextFilter" }}
                  dataField="address"
                >
                  Adresse
                </TableHeaderColumn> */}
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="150"
                  filter={{ type: "TextFilter" }}
                  dataField="phone"
                >
                  Téléphone
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="300"
                  dataAlign="center"
                  dataField="createdAt"
                  dataFormat={(cell, row) => createdAtFormatter(cell, row)}
                >
                  Date d'inscription
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="150"
                  dataField="cardId"
                  dataFormat={(cell, row) => CardIdFormatter(cell, row)}
                >
                  Carte ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="150"
                  dataFormat={(c) => formatAmout(c)}
                  dataField="funds"
                >
                  Solde
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    textAlign: "center",
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="150"
                  dataFormat={(cell, row) => actionFormatter(cell, row)}
                >
                  Action
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsTable;

export function IsActiveButton({ item }) {
  const [loading, setLoading] = React.useState(false);
  const token = useSelector((s) => s.auth.token);
  const [selected, setSelected] = React.useState(item.isActive);
  React.useEffect(() => {
    setSelected(item.isActive);
  }, [item]);

  const onSubmit = React.useCallback(async () => {
    console.log("submit");
    if (loading) return;
    const old = selected;
    const val = !old;
    setSelected(val);
    setLoading(true);

    var baseApiUrl = api + "users/";
    try {
      await Axios.put(
        baseApiUrl + `${item._id}/`,
        { isActive: val },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      setSelected(old);
    }
    setLoading(false);
  }, [selected, loading, item._id, token]);
  return (
    <div>
      {selected && (
        <i
          className="fa fa-toggle-on fa-3x text-success"
          style={{ cursor: "pointer" }}
          onClick={onSubmit}
        />
      )}
      {!selected && (
        <i
          className="fa fa-toggle-off fa-3x text-danger"
          style={{ cursor: "pointer" }}
          onClick={onSubmit}
        />
      )}
    </div>
  );
}

export function IsReadButton({ item }) {
  const [loading, setLoading] = React.useState(false);
  const token = useSelector((s) => s.auth.token);
  const [selected, setSelected] = React.useState(item.read);
  React.useEffect(() => {
    setSelected(item.read);
  }, [item]);
  const onSubmit = React.useCallback(async () => {
    console.log("submit");
    if (loading) return;
    const old = selected;
    const val = !old;
    setSelected(val);
    setLoading(true);

    var baseApiUrl = api + "contacts/";
    try {
      await Axios.put(
        baseApiUrl + `${item._id}/`,
        { read: val },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      setSelected(old);
    }
    setLoading(false);
  }, [selected, loading, item._id, token]);
  return (
    <div>
      <p>Répondu ?</p>
      {selected && (
        <i
          className="fa fa-toggle-on fa-3x text-success"
          style={{ cursor: "pointer" }}
          onClick={onSubmit}
        />
      )}
      {!selected && (
        <i
          className="fa fa-toggle-off fa-3x text-danger"
          style={{ cursor: "pointer" }}
          onClick={onSubmit}
        />
      )}
    </div>
  );
}
