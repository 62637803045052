import React from "react";
import Header from "../../Navs/Header";
import NavBarVerticalV2 from "../navbarVerticalV2/navbarVerticalV2";

import ClientsTable from "../../Tables/Orders/ClientsTable";

var Orders = () => {
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Liste des commandes</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <ClientsTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
