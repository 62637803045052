import axios from "axios";
import FeatherIcon from "feather-icons-react";
import $ from "jquery";
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import api from "../../Http/global-vars";

export default class ResetPasswordModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      api: api,
      resetRequestInProgress: false,
      isSuccess: false,
      isError: false,
      is_empty_email: false,
      is_invalid_email: false,
      fields: {},
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmitResetPasswordRequest =
      this.onSubmitResetPasswordRequest.bind(this);
  }

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields,
    });

    console.log(fields);
  };

  onValidateForm = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["email"]) {
      isValidForm = false;
      this.setState({
        is_empty_email: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_email: false,
        });
      }, 5000);

      errors["email"] = "Ce champ est obligatoire";
    }
    if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        isValidForm = false;
        this.setState({
          is_invalid_email: true,
        });
        setTimeout(() => {
          this.setState({
            is_invalid_email: false,
          });
        }, 5000);

        errors["invalid_email"] = "Votre adresse email n'est pas valide";
      }
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  };

  onSubmitResetPasswordRequest = (e) => {
    e.preventDefault();

    if (this.onValidateForm()) {
      this.setState({
        resetRequestInProgress: true,
      });
      var api = this.state.api;
      var data = this.state.fields;

      axios
        .post(api + "auth/request-reset-password", data)
        .then((response) => {
          this.setState({
            resetRequestInProgress: false,
          });

          toast.success(
            "Un code vous a été envoyé avec succès pour modifier votre mot de passe"
          );
          $("close").click();
          $("#email").val("");
          this.setState({
            isSuccess: true,
          });
          setTimeout(() => {
            this.setState({
              isSuccess: false,
            });
            window.location = "reset-password";
          }, 6000);
        })
        .catch((ex) => {
          const { error } = ex.response.data;
          toast.error(
            error ||
              "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard."
          );
          this.setState({
            resetRequestInProgress: false,
            isError: true,
          });
          setTimeout(() => {
            this.setState({
              isError: false,
            });
          }, 6000);
        });
    }
  };

  render() {
    return (
      <div
        className="modal fade open-reset-password-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content modal-card">
            <div className="row m-b-35">
              <div className="col-10 itm-center">
                <p className="title-modal">Modifier votre mot de passe</p>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <FeatherIcon icon="x" />
                  </span>
                </button>
              </div>
            </div>

            <form onSubmit={(e) => this.onSubmitResetPasswordRequest(e)}>
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text span-input-icon"
                        id="basic-addon1"
                      >
                        <FeatherIcon className="icon-input" icon="mail" />
                      </span>
                    </div>
                    <input
                      name="email"
                      id="email"
                      onChange={(e) => this.handleChange(e)}
                      type="email"
                      className="form-control input-with-icon"
                      placeholder="Adresse mail"
                    />
                    {this.state.is_empty_email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {this.state.errors.email}{" "}
                      </div>
                    )}
                    {this.state.is_invalid_email && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {this.state.errors.invalid_email}{" "}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="dis-flex justify-content-end containt-mobile-center m-t-30">
                {this.state.resetRequestInProgress === false ? (
                  <button type="submit" className="btn-theme p-l-90 p-r-90">
                    ENVOYER
                  </button>
                ) : (
                  <button type="submit" className="btn-theme p-l-90 p-r-90">
                    Envoi en cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>

        {this.state.isSuccess || this.state.isError ? <ToastContainer /> : ""}
      </div>
    );
  }
}
