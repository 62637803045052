import React from "react";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataProvider } from "../src/redux/store/GlobalState";
import Connexion from "./Components/Auth/Connexion";
import ModifierPassword from "./Components/Auth/ModifierPassword";
import ResetPassword from "./Components/Auth/ResetPassword";
import GestionDiscussion from "./Components/Backend/ADMIN/Contacts/GestionDiscussion";
import Dashboard from "./Components/Backend/ADMIN/Dashboard/Dashboard";
import GestionClients from "./Components/Backend/ADMIN/GestionClients/GestionClients";
import GestionRides from "./Components/Backend/ADMIN/GestionRides";
import GestionTransactions from "./Components/Backend/ADMIN/GestionTransactions/GestionTransactions";
import GestionVirements from "./Components/Backend/ADMIN/GestionVirements/GestionVirements";
import Orders from "./Components/Backend/ADMIN/Orders";
import Parametres from "./Components/Backend/ADMIN/Parametre/Parametre";
import { useSelector } from "./redux/store/GlobalState";

function App() {
  return (
    <DataProvider>
      <AppComponent />
    </DataProvider>
  );
}

export default App;

const AppComponent = () => {
  const auth = useSelector((s) => s.auth);

  const isLoggedIn = !!auth?.user?._id;
  console.log({ isLoggedIn });
  return (
    <Router>
      <div className="router-container">
        <ToastContainer />
        <Switch>
          <Redirect from="/" to={"/dashboard"} exact={true} />
          <Route path="/dashboard">
            {!isLoggedIn ? <Redirect to={`/se-connecter`} /> : <Dashboard />}
          </Route>

          <Route path="/se-connecter" component={Connexion} />
          <Route path="/modier-password">
            {!isLoggedIn ? (
              <Redirect to={`/se-connecter`} />
            ) : (
              <ModifierPassword />
            )}
          </Route>
          <Route path="/reset-password" component={ResetPassword} />

          <Route path="/users">
            {!isLoggedIn ? (
              <Redirect to={`/se-connecter`} />
            ) : (
              <GestionClients />
            )}
          </Route>
          <Route path="/transactions">
            {!isLoggedIn ? (
              <Redirect to={`/se-connecter`} />
            ) : (
              <GestionTransactions />
            )}
          </Route>
          <Route path="/virements">
            {!isLoggedIn ? (
              <Redirect to={`/se-connecter`} />
            ) : (
              <GestionVirements />
            )}
          </Route>
          <Route path="/contacts">
            {!isLoggedIn ? (
              <Redirect to={`/se-connecter`} />
            ) : (
              <GestionDiscussion />
            )}
          </Route>
          <Route path="/rides">
            {!isLoggedIn ? <Redirect to={`/se-connecter`} /> : <GestionRides />}
          </Route>
          <Route path="/orders">
            {!isLoggedIn ? <Redirect to={`/se-connecter`} /> : <Orders />}
          </Route>
          <Route path="/settings">
            {!isLoggedIn ? <Redirect to={`/se-connecter`} /> : <Parametres />}
          </Route>
        </Switch>
      </div>
    </Router>
  );
};
