import React from "react";
import Logo from "../../assets/images/favicon.png";

var EmptyHeader = () => {
  return (
    <div className="container-fluid empty-fluidy d-flex justify-content-center">
      <div className="empty-header">
        <div className="logo-item">
          <div className="logo-container">
            <img
              src={Logo}
              className="logo-img logo-connexion"
              style={{ width: 120, height: "auto" }}
              alt="Fybego"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyHeader;
