import React, { useState, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import { useForm } from "react-hook-form";
import { getData, putData } from "../../../../redux/utils/fetchData";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "../../../../redux/store/GlobalState";

const Cpu = ({type="cgu",title="Condition générale d'utilisation"}) => {
  let token = useSelector(s => s.auth.token)
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [cgu, setCgu] = useState({});
  const { register, handleSubmit, formState, clearErrors, setValue } =
    useForm();
    const getCgu = React.useCallback(() => {
      getData(`contents/admin/${type}`, token)
        .then((response) => {
          setCgu(response);
          setIsLoadingPage(false);
        })
        .catch((error) => {
          setIsLoadingPage(false);
          console.error(error);
        });
    },[token,type]);
  useEffect(() => {
      getCgu();
  }, [getCgu]);

  useEffect(() => {
    register("text", {
      required: "Le texte est obligatoire .",
    });
    register("type");
   
    setValue("type", "cgu");
   if(cgu._id){
    register("text");
    setValue("text", cgu.content);
   }
  }, [register, setValue,cgu]);

  useEffect(() => {
    if (formState?.errors?.length !== 0) {
      setTimeout(() => {
        clearErrors();
      }, 7000);
    }
  }, [clearErrors, formState.errors.length]);

  const onSubmit = (data) => {
    
    setIsLoading(true);
      putData(`contents/${cgu._id}/`, {content: data.text}, token)
        .then((result) => {
          console.log("result update", result);
          Swal.fire({
            icon: "success",
            title: `${title} modifiée avec succés.`,
            showConfirmButton: false,
            timer: 2000,
          });
          getCgu();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log({error})
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Une erreur est survenue lors de l'ajout.",
            showConfirmButton: false,
            timer: 2000,
          });
        });
   
  };
  return (
    
            <div className="row easypm-table-row m-t-50 m-b-50">
              <div className="col-md-12 easypm-table-col">
                <div className="table-container">
                  <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row crud-row">
                      <div className="col-md-12 mb-4 crud-col-item">
                        {!isLoadingPage ? (
                          <CKEditor
                            initData={!isLoadingPage ? cgu?.content : ""}
                            onChange={(evt) =>
                              setValue("text", evt.editor.getData())
                            }
                          />
                        ) : (
                          <Skeleton height={300} />
                        )}
                        {formState?.errors && formState?.errors?.text && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState?.errors?.text?.message}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 offset-md-9 crud-form-action-btn-container">
                        {isLoading && (
                          <button
                            className="btn btn-primary crud-submit-btn"
                            disabled
                          >
                            En cours &nbsp;
                            <i className="fas fa-spin fa-spinner"></i>
                          </button>
                        )}
                        {(!isLoading && !isLoadingPage) && (
                          <button className="btn btn-primary crud-submit-btn">
                            Enregistrer
                          </button>
                        )}
                        {isLoadingPage && <Skeleton
                            width={200}
                            style={{ padding: "15px", borderRadius: "10px" }}
                          />}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
  );
};

export default Cpu;
