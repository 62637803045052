import api from '../../Http/global-vars';

export const getDataOffers = async (url) => {
    const res = await fetch(`${api}${url}`, {
        method: 'GET'
    })
    const data = await res.json()
    return data
}
export const getDatas = async (url) => {
    const res = await fetch(`${api}${url}`, {
        method: 'GET'
    })
    const data = await res.json()
    return data
}

export const getData = async (url, token) => {
    const res = await fetch(`${api}${url}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    })
    const data = await res.json()
    return data
}

export const getDataByID = async (url, id, token) => {
    const res = await fetch(`${api}${url}/${id}/`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

    const data = await res.json()
    return data
}

export const postData = async (url, post, token) => {
    console.log('url', api)
    const res = await fetch(`${api}${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(post)
    })

    const data = await res.json()
    return data
}

export const onPostData = async (url, post) => {
    console.log('url', api)
    const res = await fetch(`${api}${url}/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(post)
    })

    const data = await res.json()
    return data
}


export const putData = async (url, post, token) => {
    const res = await fetch(`${api}${url}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(post)
    })

    const data = await res.json()
    return data
}


export const patchData = async (url, post, token) => {
    const res = await fetch(`${api}${url}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(post)
    })

    const data = await res.json()
    return data
}


export const deleteData = async (url, token) => {
    const res = await fetch(`${api}${url}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    const data = await {success: "Success"}
    return data
}

