import "intl";
import "intl/locale-data/jsonp/fr";
import baseUrl from "./backend-base-url";
// LOCAL URL
var api = `${baseUrl}/`;

export default api;

export function formatAmout(amount, currency = "EUR") {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: currency,

    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(amount);
}
