/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Header from "../../../Navs/Header";
import "./GestionDiscussion.css";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import ImgMessage from "../../../../images/others/default-profile-avatar.png";
import moment from "moment";
import PaginationComponent from "../../../Modules/Pagination/Pagination";
import { useSelector } from "../../../../redux/store/GlobalState";
import { getFileUrl } from "../../../../Http/backend-base-url";
import { IsReadButton } from "../../../Tables/Clients/ClientsTable";

function GestionDiscussion() {
  const contacts = useSelector((s) => s.contacts);
  const [per_page] = useState(4);
  const [current_page, setCurrent_page] = useState(1);
  const [total_data, setTotal_data] = useState(contacts.length);
  const [currentList, setCurrentList] = useState([]);
  React.useEffect(() => {
    setTotal_data(contacts.length);
  }, [contacts]);
  const formatData = React.useCallback(
    (Array) => {
      setTotal_data(Array.length);
      const indexOfLastPost = current_page * per_page;
      const indexOfFirstPage = indexOfLastPost - per_page;

      const currentList = Array.slice(indexOfFirstPage, indexOfLastPost);
      setCurrentList(currentList);
    },
    [current_page, per_page]
  );
  useEffect(() => {
    if (contacts?.length) {
      formatData(contacts);
    }
  }, [contacts, current_page, formatData]);

  const handleClick = (number) => {
    setCurrent_page(number);
  };

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>
          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">MESSAGES DE CONTACT</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  {currentList?.length &&
                    currentList.map((message) => {
                      return (
                        <div class="card tr-discussion" key={message?.id}>
                          <div class="card-body">
                            <div className="row d-flex align-items-center">
                              <div className="col-xl-4 col-lg-4 col-md-4">
                                <div>
                                  <a
                                    href={`mailto:${message.user.email}?subject=Réponse: ${message.title}`}
                                  >
                                    {message.title}
                                  </a>
                                  <p>{message.body}</p>
                                  <p className="p-message pl-3">
                                    Le{" "}
                                    {moment(message.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-center">
                                <IsReadButton item={message} />
                              </div>

                              <div className="col-xl-4 col-lg-4 col-md-4 d-flex justify-content-end">
                                <div className="d-flex">
                                  <img
                                    src={
                                      message.user.avatar
                                        ? getFileUrl(message.user.avatar)
                                        : ImgMessage
                                    }
                                    className="img-message"
                                  />
                                  <p className="p-message pl-3">
                                    {"Par " +
                                      message.user.firstName +
                                      " " +
                                      message.user.lastName}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {contacts?.length ? (
                    <PaginationComponent
                      per_page={per_page}
                      current_page={current_page}
                      total_data={total_data}
                      handleClick={handleClick}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GestionDiscussion;
