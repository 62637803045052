import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { DataContext } from "../../redux/store/GlobalState";
import { postData } from "../../redux/utils/fetchData";

const VirementModal = ({ user, onDone }) => {
  const { state } = useContext(DataContext);
  const { auth } = state;
  const { register, handleSubmit, reset, formState } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    var formData = {
      user: user._id,
      amount: data.amount,
    };
    postData("transactions", formData, auth.token)
      .then((res) => {
        if (res?._id) {
          onDone();
          reset();
          window.$("#virement" + user._id).modal("hide");
          toast.success("Virement effectué avec succés.");
        } else {
          toast.error("Virement non effectué");
        }

        setIsLoading(false);
      })
      .catch((ex) => {
        setIsLoading(false);
        toast.error("Virement non effectué");
      });
  };
  const handleReset = () => {
    reset();
  };
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingBannerModal">
            Ajouter un virement
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item mb-3">
                  <div
                    className="form-group crud-form-group custom-file required"
                    style={{ textAlign: "start" }}
                  >
                    <label htmlFor="amount">Montant en euro</label>
                    <input
                      type="number"
                      placeholder="Entrer le montant en euro"
                      name="amount"
                      className="form-control crud-form-control form-select  ml-md-auto upload-item upload-crud-form"
                      id="amount"
                      {...register("amount", {
                        required: "Ce champ est obligatoire",
                        max: {
                          message: `Montant maximum ${user.funds}`,
                          value: user.funds,
                        },
                      })}
                    />
                    {formState.errors && formState.errors.amount && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState.errors.amount?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row submit-btn-row">
                <div className="col-md-4 offset-md-4 crud-form-action-btn-container">
                  {isLoading && (
                    <div className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </div>
                  )}
                  {!isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      AJOUTER
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirementModal;
