import React from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import ChangePassword from "./ChangePassword";
import "react-phone-input-2/lib/bootstrap.css";
import Cpu from "./Cpu";
import Settings from "./Settings";
const DATA = [
  { type: "cgv", title: "Politique de confidentialité" },
  { type: "cgu", title: "Conditions générales d’utilisation" },
  { type: "about", title: "À propos" },
  { type: "hiw", title: "Comment ça marche ?" },
];
const Parametre = () => {
  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />
        <div className="row">
          <div className="nav-container">
            <NavBarVerticalV2 />
          </div>
          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Paramètres</h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="easypm-tabs-content">
                    <ul
                      className="nav nav-pills mb-3 easypm-tabs-nav-pills"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item easypm-tabs-nav-pills-item">
                        <a
                          className={`nav-link active`}
                          data-toggle="pill"
                          href={`#pills-settings`}
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Paramètres
                        </a>
                      </li>
                      {DATA.map((d, i) => (
                        <li
                          key={d.type}
                          className="nav-item easypm-tabs-nav-pills-item"
                        >
                          <a
                            className={`nav-link`}
                            id={d.type}
                            data-toggle="pill"
                            href={`#pills-${d.type}`}
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            {d.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <div
                      className="tab-content easypm-tabs-content-body"
                      id="pills-tabContent"
                    >
                      <div
                        className={`tab-pane easypm-tabs-pane fade show active`}
                        id={`pills-settings`}
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <Settings />
                      </div>
                      {DATA.map((d, i) => (
                        <div
                          key={d.type}
                          className={`tab-pane easypm-tabs-pane fade`}
                          id={`pills-${d.type}`}
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <Cpu {...d} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 beny"
            style={{ marginTop: "10px" }}
          >
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      Changer votre mot de de passe
                    </h1>
                  </div>
                </div>

                <div className="parent-table-container parent-tabs-container">
                  <div className="easypm-tabs-content">
                    <div
                      className="tab-content easypm-tabs-content-body"
                      id="pills-tabContent"
                    >
                      <div
                        className="tab-pane easypm-tabs-pane fade show active"
                        id="pills-informations"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <ChangePassword />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parametre;
