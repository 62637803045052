const reducers = (state, action) => {
  switch (action.type) {
    case "AUTH":
      return {
        ...state,
        auth: action.payload,
      };
    case "DASHBOARD":
      return {
        ...state,
        dashboard: action.payload,
      };
    case "SET":
      return {
        ...state,
        settings: action.payload,
      };
    case "USERS":
      return {
        ...state,
        users: action.payload,
      };
    case "ORDERS":
      return {
        ...state,
        orders: action.payload,
      };
    case "TRANSACTIONS":
      return {
        ...state,
        transactions: action.payload,
      };
    case "RIDES":
      return {
        ...state,
        rides: action.payload,
      };
    case "CONTACTS":
      return {
        ...state,
        contacts: action.payload,
      };
    case "LOGOUT":
      return {};
    default:
      return state;
  }
};

export default reducers;
