import React, { useContext } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import { DataContext } from "../../../redux/store/GlobalState";
import { formatAmout } from "../../../Http/global-vars";

var ClientsTable = ({ virement }) => {
  const { state } = useContext(DataContext);
  const transactions = virement
    ? state.transactions.filter((f) => f.debit)
    : state.transactions;

  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
    exportCSVText: "Exporter en csv",
  };

  const createdAtFormatter = (cell) => {
    return (
      <span className="created_at"> {moment(cell).format("DD/MM/YYYY")} </span>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            <div className="table-container">
              <BootstrapTable
                data={transactions}
                striped={true}
                hover={true}
                condensed={true}
                multiColumnSort={2}
                options={options}
                pagination
                search={true}
                version="4"
                bordered={false}
                // exportCSV
              >
                <TableHeaderColumn isKey hidden dataField="_id">
                  Id
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="140"
                  filter={{ type: "TextFilter" }}
                  dataFormat={(c) => c?.number}
                  filterValue={(c) => c?.number}
                  dataField="order"
                >
                  N° Commande
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="140"
                  filter={{ type: "TextFilter" }}
                  dataFormat={(c) => c.firstName}
                  filterValue={(c) => c.firstName}
                  dataField="user"
                >
                  Prénom
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="140"
                  filter={{ type: "TextFilter" }}
                  dataField="user"
                  dataFormat={(c) => c.lastName}
                  filterValue={(c) => c.lastName}
                >
                  Nom
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="250"
                  filter={{ type: "TextFilter" }}
                  dataField="user"
                  dataFormat={(c) => c.email}
                  filterValue={(c) => c.email}
                >
                  Adresse email
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="200"
                  filter={{ type: "TextFilter" }}
                  dataFormat={(c) => c.address}
                  filterValue={(c) => c.address}
                  dataField="user"
                >
                  Adresse
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="150"
                  filter={{ type: "TextFilter" }}
                  dataFormat={(c) => c.phone}
                  filterValue={(c) => c.phone}
                  dataField="user"
                >
                  Téléphone
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="150"
                  dataAlign="center"
                  dataField="date"
                  dataFormat={(cell, row) => createdAtFormatter(cell, row)}
                >
                  Date
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  width="100"
                  dataFormat={(c) => formatAmout(c)}
                  dataField="amount"
                >
                  Montant
                </TableHeaderColumn>
                {/* <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    textAlign: "center",
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  dataField="type"
                  width="70"
                >
                  Par
                </TableHeaderColumn> */}
                <TableHeaderColumn
                  thStyle={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                  }}
                  tdStyle={{
                    whiteSpace: "nowrap",
                    fontWeight: 500,
                    textAlign: "center",
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                  dataFormat={(c) => (c ? "Crédit" : "Débit")}
                  dataField="credit"
                  width="100"
                >
                  Type
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsTable;
