import React from "react";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import { useSelector } from "../../../redux/store/GlobalState";
import "./navbarMobileV2.css";
export default function NavbarMobileV2() {
  const user = useSelector((s) => s.auth?.user || {});
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white">
      <button
        className="navbar-toggler ml-sm-3"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse bg-theme" id="navbarNavDropdown">
        <div className="py-4">
          <div className="mobile-menu-container" id="scrollbar-nav-mobile">
            <div className="mobile-menu-content content-nav-mobile">
              <ul className="mobile-menu-ul">
                <li>
                  <NavLink
                    className="mobile nav-link nav-link-vertival-custom"
                    to="/dashboard"
                    activeClassName="actived"
                  >
                    <IconlyPack.Chart
                      set="bold"
                      // primaryColor="white"
                      className="icon-navbar-vertical"
                    />
                    <span className="mobile-navlink-label">
                      Tableau de bord
                    </span>
                  </NavLink>
                </li>

                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="mobile nav-link-vertival-custom"
                    to="/users"
                    activeClassName="actived"
                  >
                    <IconlyPack.People
                      set="bold"
                      // primaryColor="white"
                      className="icon-navbar-vertical"
                    />
                    <span className="mobile-navlink-label">Utilisateurs</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="mobile nav-link-vertival-custom"
                    to="/transactions"
                    activeClassName="actived"
                  >
                    <IconlyPack.Bag
                      set="bold"
                      // primaryColor="white"
                      className="icon-navbar-vertical"
                    />
                    <span className="mobile-navlink-label">Transactions</span>
                  </NavLink>
                </li>

                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="mobile nav-link-vertival-custom"
                    to="/rides"
                    activeClassName="actived"
                  >
                    <IconlyPack.Wallet
                      set="bold"
                      // primaryColor="white"
                      className="icon-navbar-vertical"
                    />
                    <span className="mobile-navlink-label">Courses</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="mobile nav-link-vertival-custom"
                    to="/orders"
                    activeClassName="actived"
                  >
                    <IconlyPack.Activity
                      set="bold"
                      // primaryColor="white"
                      className="icon-navbar-vertical"
                    />
                    <span className="mobile-navlink-label">Commandes</span>
                  </NavLink>
                </li>
                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="mobile nav-link-vertival-custom"
                    to="/contacts"
                    activeClassName="actived"
                  >
                    <IconlyPack.Message
                      set="bold"
                      // primaryColor="white"
                      className="icon-navbar-vertical"
                    />
                    <span className="mobile-navlink-label">Messages</span>
                  </NavLink>
                </li>

                <li className="nav-item nav-item-vertical-custom">
                  <NavLink
                    className="mobile nav-link-vertival-custom"
                    to="/settings"
                    activeClassName="actived"
                  >
                    <IconlyPack.Setting
                      set="bold"
                      // primaryColor="white"
                      className="icon-navbar-vertical"
                    />
                    <span className="mobile-navlink-label">Paramétres</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
