import React, { createContext, useReducer, useContext } from "react";
import { getData } from "../utils/fetchData";
import reducers from "./Reducers";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const initialState = {
    auth: JSON.parse(window.sessionStorage.getItem("auth") || null),
    transactions: [],
    settings: {},
    rides: [],
    orders: [],
    contacts: [],
    dashboard: {
      livreurs: 0,
      voyageurs: 0,
      users: 0,
      rides: 0,
      orders: 0,
    },
    users: [],
  };
  const [state, dispatch] = useReducer(reducers, initialState);
  const token = state.auth?.token;
  React.useEffect(() => {
    if (token) {
      getData("settings", token)
        .then((res) => {
          dispatch({
            type: "SET",
            payload: res,
          });
        })
        .catch((ex) => null);
      getData("dashboard", token)
        .then((res) => {
          dispatch({
            type: "DASHBOARD",
            payload: res,
          });
        })
        .catch((ex) => null);
      getData("orders", token)
        .then((res) => {
          dispatch({
            type: "ORDERS",
            payload: res,
          });
        })
        .catch((ex) => null);
      getData("users", token)
        .then((res) => {
          dispatch({
            type: "USERS",
            payload: res,
          });
        })
        .catch((ex) => null);
      getData("transactions", token)
        .then((res) => {
          dispatch({
            type: "TRANSACTIONS",
            payload: res,
          });
        })
        .catch((ex) => null);
      getData("rides", token)
        .then((res) => {
          dispatch({
            type: "RIDES",
            payload: res,
          });
        })
        .catch((ex) => null);
      getData("contacts", token)
        .then((res) => {
          dispatch({
            type: "CONTACTS",
            payload: res,
          });
        })
        .catch((ex) => null);
    }
  }, [token]);
  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};

export function useSelector(cb) {
  const { state } = useContext(DataContext);
  return cb(state);
}

export function useDispatch() {
  const { dispatch } = useContext(DataContext);
  return dispatch;
}
