import React, { useContext, useEffect } from "react";
import "./navbarContent.css";
import { NavLink } from "react-router-dom";
// import FeatherIcon from 'feather-icons-react';
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../../redux/store/GlobalState";

export default function NavbarContent() {
  return (
    <div className="hoverable">
      <ul className="nav nav-items-custom flex-column">
        <li className="nav-item nav-item-vertical-custom">
          <NavLink
            className="nav-link nav-link-vertival-custom"
            to="/dashboard"
            activeClassName="actived"
          >
            <IconlyPack.Chart
              set="bold"
              secondaryColor="white"
              className="icon-navbar-vertical"
            />
            <span className="hiddenable-navlink-label">Tableau de bord</span>
          </NavLink>
        </li>

        <li className="nav-item nav-item-vertical-custom">
          <NavLink
            className="nav-link nav-link-vertival-custom"
            to="/users"
            activeClassName="actived"
          >
            <IconlyPack.People
              set="bold"
              // primaryColor="white"
              className="icon-navbar-vertical"
            />
            <span className="hiddenable-navlink-label">Utilisateurs</span>
          </NavLink>
        </li>
        <li className="nav-item nav-item-vertical-custom">
          <NavLink
            className="nav-link nav-link-vertival-custom"
            to="/transactions"
            activeClassName="actived"
          >
            <IconlyPack.Bag
              set="bold"
              // primaryColor="white"
              className="icon-navbar-vertical"
            />
            <span className="hiddenable-navlink-label">Transactions</span>
          </NavLink>
        </li>

        <li className="nav-item nav-item-vertical-custom">
          <NavLink
            className="nav-link nav-link-vertival-custom"
            to="/rides"
            activeClassName="actived"
          >
            <IconlyPack.Wallet
              set="bold"
              // primaryColor="white"
              className="icon-navbar-vertical"
            />
            <span className="hiddenable-navlink-label">Courses</span>
          </NavLink>
        </li>
        <li className="nav-item nav-item-vertical-custom">
          <NavLink
            className="nav-link nav-link-vertival-custom"
            to="/orders"
            activeClassName="actived"
          >
            <IconlyPack.Activity
              set="bold"
              // primaryColor="white"
              className="icon-navbar-vertical"
            />
            <span className="hiddenable-navlink-label">Commandes</span>
          </NavLink>
        </li>
        <li className="nav-item nav-item-vertical-custom">
          <NavLink
            className="nav-link nav-link-vertival-custom"
            to="/contacts"
            activeClassName="actived"
          >
            <IconlyPack.Message
              set="bold"
              // primaryColor="white"
              className="icon-navbar-vertical"
            />
            <span className="hiddenable-navlink-label">Messages</span>
          </NavLink>
        </li>

        <li className="nav-item nav-item-vertical-custom">
          <NavLink
            className="nav-link nav-link-vertival-custom"
            to="/settings"
            activeClassName="actived"
          >
            <IconlyPack.Setting
              set="bold"
              // primaryColor="white"
              className="icon-navbar-vertical"
            />
            <span className="hiddenable-navlink-label">Paramétres</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
