import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";

import moment from "moment";
// import 'moment/locale/fr'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import fr from 'date-fns/locale/fr'
import { fr } from "date-fns/locale";
import { useSelector } from "../../../../redux/store/GlobalState";
import Header from "../../../Navs/Header";
// import api from './../../http/global-vars'
// import axios from 'axios'
registerLocale("fr", fr);
moment.updateLocale("fr", {
  week: {
    dow: 1, // Monday is the first day of the week
  },
});

var Dashboard = (props) => {
  var [isPageLoading] = useState(false);
  const { livreurs, voyageurs, users, rides, orders } = useSelector(
    (s) => s.dashboard
  );
  const [date, setDate] = React.useState(new Date());
  const handleChangeDate = (date) => {
    setDate(date);
  };

  const CardSkeleton = () => {
    const renderModules = Array(6)
      .fill()
      .map((item, index) => (
        <div
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2"
          key={index}
        >
          <div className="card">
            <Skeleton
              variant="rect"
              style={{ borderRadius: "10px" }}
              width={332}
              height={100}
            />
          </div>
        </div>
      ));
    return <React.Fragment>{renderModules}</React.Fragment>;
  };

  return (
    <div className="body-background-dashboard">
      <Header />
      <div className="row container-fluid">
        <div className="nav-container no-view-mobile">
          <NavBarVerticalV2 />
        </div>
        <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-6 mb-2">
                <h6 className="title-view-dashboard">Tableau de Bord</h6>
              </div>
              <div
                className="col-xl-4 offset-xl-2 col-md-5 offset-md-1 col-sm-6 mb-2 dis-flex"
                style={{ justifyContent: "flex-end" }}
              >
                {isPageLoading && (
                  <DatePicker
                    placeholderText="Par mois"
                    dateFormat="MMMM/yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    maxDate={new Date()}
                    className="form-select form-select-line-chart ml-md-auto"
                    selected={date}
                    onChange={(date) => handleChangeDate(date, "month")}
                    locale={fr}
                  />
                )}
              </div>
            </div>
            <div className="row mb-3 px-2">
              {isPageLoading && <CardSkeleton />}
              {!isPageLoading && (
                <React.Fragment>
                  <DashboardItem title={"Utilisateurs"} count={users} />
                  <DashboardItem title={"Voyageurs"} count={voyageurs} />
                  <DashboardItem title={"Courses"} count={rides} />
                  <DashboardItem title={"Commandes"} count={orders} />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

function DashboardItem({ title, count }) {
  return (
    <div className="col-sm-6 d-flex px-2">
      <div className="card-statistics card-statistics-color-1">
        <div className="stat-conteny">
          <p
            className="parent-label-card-statistics"
            style={{ fontSize: "2rem" }}
          >
            {title}
          </p>

          <div className="pt-4">
            <div className="label-stat-counter" style={{ fontSize: "5rem" }}>
              {count}
              <span className="child-label-card-statistics texte-chiffre"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
