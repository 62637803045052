import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSelector } from "../../../../redux/store/GlobalState";
import { putData } from "../../../../redux/utils/fetchData";

const ChangePassword = () => {
  const token = useSelector((s) => s.auth.token);
  const user = useSelector((s) => s.auth.user);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error] = useState(null);
  const { register, handleSubmit, formState, watch, clearErrors, reset } =
    useForm();

  useEffect(() => {
    register("new_password", {
      required: {
        value: true,
        message: "Le nouveau mot de passe est requis.",
      },
    });
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, formState?.errors, clearErrors, register]);

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    delete data?.confirm_password;
    const newData = {
      password: data.old_password,
      newPassword: data.new_password,
    };
    putData(`users/${user._id}/change-password`, newData, token)
      .then((res) => {
        setIsLoading(false);
        if (res?.success) {
          toast.success("Mot de passe modifié avec succés.");
          e.target.reset();
        } else {
          toast.error("Mot de passe incorrect.");
        }
        //#5DADE2

        reset();
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.data?.errors?.password) {
          toast.error("Mot de passe incorrect.");
        } else {
          toast.error("Oops! Une erreur est survenue lors de la modification.");
        }
      });
  };
  return (
    <div className="easypm-tabs-item-content-container">
      <div className="easypm-tabs-item">
        <div className="add-family-form-container">
          <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row row-form">
              <div className="col-md-4 crud-col-item">
                <div className="form-group crud-form-group required">
                  <label htmlFor="old_password">Ancien mot de passe</label>
                  <input
                    {...register("old_password", {
                      required: "L'ancien mot de passe est requis.",
                    })}
                    type="password"
                    placeholder="Ancien mot de passe"
                    name="old_password"
                    className="form-control crud-form-control"
                    id="old_password"
                  />
                  {formState?.errors && formState?.errors?.old_password && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.old_password?.message}
                    </div>
                  )}
                  {error && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {error}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-4 crud-col-item">
                <div className="form-group crud-form-group required">
                  <label htmlFor="new_password">Nouveau mot de passe</label>
                  <input
                    {...register("new_password", {
                      required: "Le nouveau mot de passe est requis.",
                    })}
                    type="password"
                    placeholder="Nouveau mot de passe"
                    name="new_password"
                    className="form-control crud-form-control"
                    id="new_password"
                  />
                  {formState?.errors && formState?.errors?.new_password && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.new_password?.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-4 crud-col-item">
                <div className="form-group crud-form-group required">
                  <label htmlFor="confirm_password">
                    Confirmer le nouveau mot de passe
                  </label>
                  <input
                    {...register("confirm_password", {
                      required: "La confirmation du mot de passe est requis.",
                      validate: (value) => {
                        return (
                          value === watch("new_password") ||
                          "Veuillez entrer deux mots de passe identiques."
                        );
                      },
                    })}
                    type="password"
                    placeholder="Confirmer le nouveau mot de passe"
                    name="confirm_password"
                    className="form-control crud-form-control"
                    id="confirm_password"
                  />
                  {formState?.errors && formState?.errors?.confirm_password && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.confirm_password?.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-3 offset-md-9 crud-form-action-btn-container">
                {isLoading && (
                  <button className="btn btn-primary crud-submit-btn" disabled>
                    En cours &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
                {!isLoading && (
                  <button className="btn btn-primary crud-submit-btn">
                    Modifier
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
